export default {
    layout: {
        title: 'Layout',
    },
    log: {
        overview: {
            title: 'Logs',
        },
        field: {
            printed: {
                label: 'Geprint?',
            },
            id: {
                label: 'ID',
            },
            choice: {
                label: 'Keuze',
            },
            productcode: {
                label: 'Product Code',
            },
            codingsystem: {
                label: 'Codestelsel',
            },
            date: {
                date: 'Datum',
                time: 'Tijd',
                label: 'Datum'
            },
            errorMessage: {
                label: 'Foutmelding',
            },
            errorCode: {
                label: 'Foutcode',
            },
            keyName: {
                label: 'Key naam',
            },
            keyValue: {
                label: 'Key waarde',
            },
            keyGuid: {
                label: 'Key Guid',
            },
            language: {
                label: 'Taal'
            },
            subId: {
                label: 'SubId'
            },
            videoViews: {
                label: 'VideoViews'
            }
        },
        assets: {
            users: 'Gebruikers',
            globalValues: 'Instellingen',
            globalFiles: 'Bijzondere bestanden',
            emailTemplates: 'Email sjablonen',
        },
        months: {
            overview: {
                title: 'Maandelijkse Logs',
                empty: 'Geen logs'
            },
            field: {
                month: { label: 'Maand' },
                uuid: { label: 'UUID' },
            },
        },
    },
    user: {
        overview: {
            title: 'Gebruikers',
            fullName: 'Naam',
            createButton: 'Nieuwe gebruiker',
            addButton: 'Nieuwe gebruiker toevoegen'
        },
        edit: {
            title: 'Gebruiker aanpassen',
        },
        create: {
            title: 'Gebruiker toevoegen',
        },
        account: {
            title: 'Account',
            logoutButton: 'Uitloggen',
            info: {
                title: 'Account Info',
            },
            password: {
                title: 'Wachtwoord veranderen',
            },
            logout: {
                title: 'Uitloggen',
                confirm: 'Weet je zeker dat je uit wilt loggen?'
            },
        },
        field: {
            fullName: { label: 'Naam' },
            firstName: { label: 'Voornaam' },
            lastName: { label: 'Achternaam' },
            email: { label: 'E-mail' },
            language: { label: 'Taal' },
            password: { label: 'Wachtwoord' },
            dateJoined: { label: 'Toegevoegd Op' },
            roles: { label: 'Rollen' },
            groups: {
                label: 'Groepen',
                value: {
                    superuser: 'Supergebruiker',
                    admin: 'Administrator',
                    manager: 'Manager',
                },
                empty: 'Geen groepen toegekend',
            },
            isActive: {
                label: 'Actief',
                options: {
                    all: 'Alles',
                    yes: 'Actief',
                    no: 'Niet actief'
                }
            },
        },
        login: {
            title: 'Inloggen',
            forgotPasswordLink: 'Wachtwoord vergeten?',
            errors: {
                invalidCredentials: 'Gebruikernaam of wachtwoord klopt niet',
                unknown: 'Onbekende fout, status code: {{status}}',
            },
            loginButton: 'Inloggen',
        },
        passwordForgot: {
            title: 'Wachtwoord vergeten',
            requestButton: 'Stuur email',
            requestedEmailText:
                'Er is een email met een wachtwoord reset code verzonden naar uw email adres.',
            loggedInError: 'Je bent ingelogd. Je kunt alleen wachtwoord vergeten aanvragen als je uitgelogd bent.',
        },
        resetPassword: {
            title: 'Wachtwoord resetten',
            resetButton: 'Reset',
            saveSuccess: 'Wachtwoord is succesvol gereset',
            loggedInError: 'Je bent ingelogd. Je kunt alleen wachtwoord resetten als je uitgelogd bent.',
        },
        changePassword: {
            title: 'Wachtwoord veranderen',
            field: {
                passwordOld: {
                    label: 'Huidig wachtwoord',
                },
                passwordNew: {
                    label: 'Nieuw wachtwoord',
                },
            },
            saveButton: 'Verander wachtwoord',
        },
        role: {
            superuser: 'superadmin',
            admin: 'admin',
        },
    },
    key: {
        overview: {
            title: 'API keys',
            createButton: {
                label: 'Nieuwe API key',
            },
            environment: {
                label: 'Kratz Omgeving',
            },
            name: {
                label: 'Naam',
            },
            value: {
                label: 'Waarde',
            },
        },
        create: {
            title: 'Nieuwe Api Key',
        },
        edit: {
            title: 'Bewerk Api Key',
        },
        field: {
            environment: {
                label: 'Kratz Omgeving',
            },
            name: {
                label: 'Naam',
            },
            value: {
                label: 'Waarde',
            },
            guid: {
                label: 'Kratz GUID',
            },
            kijksluiter: {
                label: 'Kijksluiter',
            },
            enableImages: {
                label: 'Afbeeldingen tonen',
            },
            enableVideos: {
                label: 'Videos tonen',
            },
        },
    },
    parameters: {
        title: 'Parameters',
        field: {
            codingsystem: {
                label: 'Coding systeem',
            },
            zi_number: {
                label: 'Medicijnnummer',
            },
            age_input: {
                label: 'Leeftijd in',
            },
            age_years: {
                label: 'Jaren',
            },
            age_months: {
                label: 'Maanden',
            },
            age_birth_date: {
                label: 'Geboortedatum',
            },
            dosage: {
                label: 'Dosering',
            },
            gender: {
                label: 'Geslacht',
            },
            personalized: {
                label: 'Gepersonaliseerd',
            },
            sub_id: {
                label: 'Sub Id'
            },
            language: {
                label: 'Taal'
            }
        },
    },
    preview: {
        title: 'Preview',
        field: {
            type: {
                label: 'Soort preview',
            },
        },
    },
    tooltips: {
        layout: 'Layout',
        masquerade: 'Voordoen als',
        edit: 'Aanpassen',
        delete: 'Verwijderen',
        view: 'Bekijk',
        restore: 'Herstel',
    },
    form: {
        repeat: 'Herhaal',
        fileType: {
            pdf: 'PDF',
            image: 'IMG',
            xls: 'XLS',
            any: 'Anders',
            none: 'Nog geen bestand geüpload',
        },
        saveButton: 'Opslaan',
        multiPick: {
            searchPlaceholder: 'Selecteer…',
            selectedText: '$1 uit $2 geselecteerd',
            noneSelectedText: 'Niets geselecteerd',
            selectAllButton: 'Alle',
            selectNoneButton: 'Geen',
        },
        notifications: {
            saveSuccess: 'Succesvol opgeslagen',
            deleteSuccess: 'Succesvol verwijderd',
            saveError: 'Fout met opslaan ({{status}})',
            saveValError: 'Niet alle informatie is correct ingevuld',
            saveAuthError: 'E-mailadres en wachtwoord komen niet overeen',
            newAppVersion:
                'Nieuwe versie beschikbaar, klik om de pagina te verversen',
        },
        submitButton: 'Verstuur',
        cancelButton: 'Annuleren',
        downloadButton: 'Download',
        addButton: 'Toevoegen',
        searchLabel: 'Zoek',
        search: 'Zoek',
        selectPlaceholder: ' --- KIES --- ',
        saveLoadingButton: 'Bezig met opslaan',
        deleteConfirmation: 'Weet u zeker dat u dit wilt verwijderen?',
        restoreConfirmation: 'Weet u zeker dat u dit wilt herstellen?',
        startDate: 'Van',
        endDate: 'Tot',
        no: 'Nee',
        yes: 'Ja',
        either: 'Beide',
    },
    crash: {
        heading: 'Applicatie gecrasht',
        subHeading1: 'Onze excuses, maar de applicatie is gecrasht.',
        subHeading2: 'Ons team is op de hoogte gesteld.',
        reload: 'Herlaad pagina'
    },
    emailTemplate: {
        overview: {
            title: 'Email sjablonen',
            addButton: 'Nieuw sjabloon',
        },
        create: { title: 'Mail sjabloon aanmaken' },
        edit: { title: 'Mail sjabloon aanpassen' },
        add: { title: 'Mail sjabloon toevoegen' },
        field: {
            id: { label: 'ID' },
            isDefault: { label: 'Is standaard' },
            name: { label: 'Naam' },
            emailType: { label: 'Email type' },
            subject: { label: 'Onderwerp' },
            textContent: { label: 'Text variant van email', placeholder: 'Sommige mail clients supporten alleen tekst' },
            senderEmail: { label: 'Verzender' },
        }
    },
    emailTypes: {
        password_reset: 'Wachtwoord reset',
        user_activation: 'Account activatie',
    },
    daycy: {
        week: {
            label: 'Wk',
            number: '{{weekNumber}}',
        },
        weekDay: {
            monday: 'Ma',
            tuesday: 'Di',
            wednesday: 'Wo',
            thursday: 'Do',
            friday: 'Vr',
            saturday: 'Za',
            sunday: 'Zo',
        },
        month: {
            january: 'Januari',
            february: 'Februari',
            march: 'Maart',
            april: 'April',
            may: 'Mei',
            june: 'Juni',
            july: 'Juli',
            august: 'Augustus',
            september: 'September',
            october: 'Oktober',
            november: 'November',
            december: 'December',
        },
    },
    pageNotFoundMessage: 'Pagina niet gevonden',
    brandingMessage: 'Powered by Code Yellow',
    option: {
        yes: 'Ja',
        no: 'Nee',
    },
    download: {
        progress: 'Progressie',
        close: 'Sluiten'
    }
};
