import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Route as BaseRoute, Switch, Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Load from './Loadable';
import { ModuleRepository } from '_iae/module/repository';
import { LoginForgot, ResetPassword, ViewStore } from '@code-yellow/spider';

// Use the sentry route to enable parameter discovery by react router before
// sending that information to sentry

const Route = Sentry.withSentryRouting(BaseRoute);
const UserOverview = Load(() => import('../screen/User/Overview'));
const UserEdit = Load(() => import('../screen/User/Edit'));

const AccountDetails = Load(() => import('../screen/AccountDetails'));
const LogOverview = Load(() => import('screen/Log/Overview'));
const MonthsLogOverview = Load(() => import('screen/Log/MonthOverview'));
const KeyOverview = Load(() => import('../screen/ApiKey/Overview'));
const KeyEdit = Load(() => import('../screen/ApiKey/Edit'));
const LayoutEdit = Load(() => import('../screen/ApiKey/Layout/Edit'));
const Changelog = Load(() => import('../screen/Changelog'));

const EmailTemplateOverview = Load(() => import('../screen/EmailTemplates/Overview'));
const EmailTemplateEdit = Load(() => import('../screen/EmailTemplates/Edit'));


const NotFound = Load(() => import('../container/NotFound'));

export default class Router extends Component {
    static propTypes = {
        store: PropTypes.instanceOf(ViewStore).isRequired,
        moduleRepository: PropTypes.instanceOf(ModuleRepository).isRequired
    };

    // react-router is a bit too verbose so I made a shorthand
    route = Screen => {
        const { moduleRepository } = this.props;
        return rProps => <Screen {...rProps} viewStore={this.props.store} moduleRepository={moduleRepository}/>;
    };

    redirectFromHome = () => {
        const { currentUser } = this.props.store;
        let uri = currentUser.isSuperuser ? (
            '/assets/user/overview'
        ) : (
            '/account/details'
        );

        return <Redirect to={uri}/>;
    };

    render() {
        const { moduleRepository } = this.props;

        return (
            <Switch>
                <Route exact path="/" render={this.redirectFromHome}/>

                <Route path="/log/overview" render={this.route(LogOverview)}/>
                <Route path="/log/months/overview" render={this.route(MonthsLogOverview)}/>
                <Route
                    path="/account/details"
                    render={this.route(AccountDetails)}
                />
                <Route path="/assets/user/overview" render={this.route(UserOverview)}/>
                <Route path="/assets/user/:id/edit" render={this.route(UserEdit)}/>
                <Route path="/assets/user/add" render={this.route(UserEdit)}/>

                <Route path="/assets/email-template/overview" render={this.route(EmailTemplateOverview)} />
                <Route path="/assets/email-template/add" render={this.route(EmailTemplateEdit)}/>
                <Route path="/assets/email-template/:id/edit" render={this.route(EmailTemplateEdit)}/>


                <Route path="/account/details" render={this.route(AccountDetails)}/>
                <Route path="/account/changelog" render={this.route(Changelog)}/>
                <Route path="/login/forgot" render={this.route(LoginForgot)}/>
                <Route
                    path="/user/:id/reset-password/:token"
                    render={this.route(ResetPassword)}
                />
                <Route
                    path="/assets/user/overview"
                    exact
                    render={this.route(UserOverview)}
                />
                <Route path="/assets/user/add" render={this.route(UserEdit)}/>
                <Route path="/assets/user/:id/edit" render={this.route(UserEdit)}/>


                <Route
                    path="/manage/key"
                    exact
                    render={this.route(KeyOverview)}
                />

                <Route
                    path="/manage/key/:id/edit"
                    render={this.route(KeyEdit)}
                />

                <Route
                    path="/manage/key/:id/layout"
                    render={this.route(LayoutEdit)}
                />

                <Route path="/manage/key/add" render={this.route(KeyEdit)}/>


                {moduleRepository.getRoutes().map(moduleRoute => {
                    return <Route path={moduleRoute.path} render={this.route(moduleRoute.screen)}/>
                })}

                <Route render={this.route(NotFound)}/>

            </Switch>
        );
    }
}
